








































































































import Vue from "vue";
import { SessionDataObject } from "@/types/interfaces";
import {
    sessionHelpSessionIsActive,
    sessionHelpSessionIsOver,
    sessionHelpEarlyAccessMinutes
} from "@/services/session-helpers";
import { formatDistance, subMinutes, differenceInSeconds } from "date-fns";

export default Vue.extend({
    props: {
        session: {
            type: Object,
            default() {
                return {} as SessionDataObject;
            }
        }
    },
    data() {
        return {
            showingBios: false,
            isSessionActive: false,
            isSessionOver: false,
            sessionLabelInterval: 0,
            timeLabel: ""
        };
    },
    computed: {
        /**
         * computed
         */
        sessionDateTime(): Date {
            const iso = this.session.startTime;
            return new Date(iso);
        },

        sessionEarlyAccessMinutes(): Date {
            const session = this.session;
            const returnValue = sessionHelpEarlyAccessMinutes(session);

            return returnValue;
        },

        startTimeAdjusted(): Date {
            const earlyMinutes = this.sessionEarlyAccessMinutes;
            const startTime = this.sessionDateTime;

            return subMinutes(startTime, +earlyMinutes);
        }
    },
    /**
     * lifecycle
     */
    created() {
        this.handleActiveSessionCheck();
        this.sessionLabelInterval = window.setInterval(() => {
            this.handleActiveSessionCheck();
        }, 1000);
    },
    beforeDestroy() {
        window.clearInterval(this.sessionLabelInterval);
    },
    methods: {
        /**
         * methods
         */
        handleActiveSessionCheck() {
            const session = this.session;
            const startDate = this.sessionDateTime;

            this.isSessionActive = sessionHelpSessionIsActive(session);
            this.isSessionOver = sessionHelpSessionIsOver(session);

            this.timeLabel = formatDistance(window.MgServerTime, startDate);
        },

        toggleBiographies() {
            this.showingBios = !this.showingBios;
        },

        goToSession() {
            const url = this.session.url;

            if (!this.isSessionActive) return;

            if (url) {
                let isUrl = false;
                try {
                    new URL(url);
                    isUrl = true;
                } catch (error) {
                    // no need to log error
                }

                if (isUrl) {
                    window.open(url, undefined, "noopener,noreferrer");
                } else {
                    this.$router.push(url);
                }
            } else if (this.session.speakeasyType) {
                this.$router.push({
                    path: `/meeting/SESSION${this.session.sessionId}`
                });
            } else {
                const startTime = this.startTimeAdjusted;
                const timeSinceStart = differenceInSeconds(
                    window.MgServerTime,
                    startTime
                );

                this.$router.push({
                    name: "Session",
                    params: {
                        id: this.session.sessionId || "",
                        startVideoAt: `${timeSinceStart}`
                    }
                });
            }
        }
    }
});
