import { render, staticRenderFns } from "./EvalsList.vue?vue&type=template&id=042c6b83&scoped=true&"
import script from "./EvalsList.vue?vue&type=script&lang=ts&"
export * from "./EvalsList.vue?vue&type=script&lang=ts&"
import style0 from "./EvalsList.vue?vue&type=style&index=0&id=042c6b83&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "042c6b83",
  null
  
)

export default component.exports