

















import Vue from "vue";
import Spinners from "@/components/utilities/Spinners.vue";
import { SessionDataObject, SessionSpeakerObject } from "@/types/interfaces";

import { getModule } from "vuex-module-decorators";
import sessionVuexModule from "@/store/vuex-modules/getSessionData";
const sessionStore = getModule(sessionVuexModule);

import AttendeeCard from "@/components/shared/AttendeeCard.vue";

export default Vue.extend({
    components: {
        AttendeeCard,
        Spinners
    },

    computed: {
        allSpeakers(): Array<SessionSpeakerObject> {
            const upcomingSessions = sessionStore.sessionData.upcomingSessions;
            const onDemandSessions = sessionStore.sessionData.onDemand;

            let sessions = [] as Array<SessionDataObject>;

            sessions = [...upcomingSessions, ...onDemandSessions];

            if (sessions.length) {
                const Rdup = sessions
                    .filter((item) => {
                        if (
                            Array.isArray(item?.speakers) &&
                            item.speakers.length
                        ) {
                            return item;
                        }
                    })
                    .flatMap((item) => item.speakers)
                    .filter((item) => item.id && item.lastName)
                    .sort((a: any, b: any) => {
                        return a.lastName?.localeCompare(b.lastName);
                    });

                return [
                    ...new Map(Rdup.map((item) => [item["id"], item])).values()
                ];
            } else {
                return [];
            }
        }
    }
});
