























































































































































































































































































































































































































































import eventHub from "@/event-hub";
import { a11yFixBuefyModalAriaAttrs } from "@/services/a11y";

import { CalendarTypes } from "@/types/enums";
import svgInPerson from "@/components/svg/svg-inPerson.vue";
import svgMeetingLocation from "@/components/svg/svg-meetingLocation.vue";
import svgVirtual from "@/components/svg/svg-virtual.vue";

import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import {
    AttendeeScheduleSlot,
    User,
    UserInfo,
    SessionDataObject,
    AttendeeDataObject
} from "@/types/interfaces";
import AttendeeCard from "@/components/shared/AttendeeCard.vue";
import Spinners from "@/components/utilities/Spinners.vue";

import schedulingVuexModule from "@/store/vuex-modules/scheduling";
const schedulingStore = getModule(schedulingVuexModule);

import appointmentsVuexModule from "@/store/vuex-modules/appointments";
const appointmentsStore = getModule(appointmentsVuexModule);

import attendeeScheduleVuexModule from "@/store/vuex-modules/getAttendeeSchedule";
const attendeeScheduleStore = getModule(attendeeScheduleVuexModule);

import calendarVuexModule from "@/store/vuex-modules/calendar";
const calendarStore = getModule(calendarVuexModule);

import sessionVuexModule from "@/store/vuex-modules/getSessionData";
const sessionStore = getModule(sessionVuexModule);

import {
    sessionHelpSessionIsActive,
    sessionHelpSessionIsOver,
    sessionHelpEarlyAccessMinutes
} from "@/services/session-helpers";

import { differenceInSeconds, subMinutes } from "date-fns";
import { reject } from "lodash";

export default Vue.extend({
    data() {
        return {
            isSessionActive: false,
            isSessionOver: false,
            isLoadingCalendarLinks: false,
            showAcceptModal: false,
            showCalendarLinksModal: false,
            savingRSVP: false,
            saveError: false,
            errorStatus: null,
            removeError: false,
            isRemovingItem: false,
            scheduleSlotInterval: -1,
            isActiveData: false,
            isMeetingClick: false,
            isAccepted: false,
            timeSinceStart: 0,
            myEarlyMins: 0
        };
    },
    components: {
        AttendeeCard,
        Spinners,
        svgInPerson,
        svgMeetingLocation,
        svgVirtual
    },
    directives: {
        addbuefymodal: {
            bind: function(el) {
                eventHub.$emit("BuefyModalBound", el);
            }
        }
    },
    props: {
        slotData: {
            type: Object,
            default() {
                return {} as AttendeeScheduleSlot;
            }
        }
    },
    computed: {
        /**
         * getters
         */
        user(): User {
            return this.$store.getters.user;
        },

        userInfo(): UserInfo {
            return this.$store.getters.userInfo;
        },

        sessionEarlyAccessMinutes(): Date {
            const session = this.slotData;
            const returnValue = sessionHelpEarlyAccessMinutes(session);

            return returnValue;
        },

        layoutOptions(): Record<string, string> {
            return this.$store.getters.layoutOptions;
        },

        itemTypeText(): string {
            if (this.slotData.type === "appointments") {
                return "Appointment";
            } else {
                return "Session";
            }
        },

        isMyMeeting(): boolean {
            return (
                this.isNetworking &&
                Boolean(
                    this.$store.getters.myBespeakeId ===
                        this.networkingData?.hostId
                )
            );
        },

        hostAttendee(): AttendeeDataObject {
            const id = this.networkingData?.hostId || "";
            return this.getScheduleAttendee(id)
                ? this.getScheduleAttendee(id)
                : {};
        },

        actionStatus(): string {
            return this.slotData?.status;
        },

        networkingData(): Record<string, any> {
            return this.slotData?.networking;
        },

        networkingConfirmed(): Record<string, any> {
            return this.slotData?.networking?.confirmed || [];
        },

        networkingPending(): Array<string> {
            return this.slotData?.networking?.pending || [];
        },

        networkingDeclined(): Array<string> {
            return this.slotData?.networking?.declined || [];
        },

        confirmedNames(): Array<string> {
            const returnValue: Array<string> = [];

            this.networkingConfirmed.forEach((id: string) => {
                const attendee = this.getScheduleAttendee(id);
                if (attendee) {
                    returnValue.push(
                        `${attendee.firstName} ${attendee.lastName}`
                    );
                }
            });

            return returnValue;
        },

        pendingNames(): Array<string> {
            const returnValue: Array<string> = [];

            this.networkingPending.forEach((id) => {
                const attendee = this.getScheduleAttendee(id);
                if (attendee) {
                    returnValue.push(
                        `${attendee.firstName} ${attendee.lastName}`
                    );
                }
            });

            return returnValue;
        },

        removedDeclinedName(): Array<string> {
            let returnValue: Array<string> = [];

            if (this.isAccepted) {
                returnValue = this.declinedNames;
            }
            return returnValue;
        },

        declinedNames(): Array<string> {
            let returnValue: Array<string> = [];

            this.networkingDeclined.forEach((id) => {
                const attendee = this.getScheduleAttendee(id);
                if (attendee) {
                    returnValue.push(
                        `${attendee.firstName} ${attendee.lastName}`
                    );
                }
            });

            if (this.isAccepted) {
                const myOwnName = this.userInfo.name;
                returnValue = returnValue.filter((item) => item != myOwnName);
            }
            return returnValue;
        },

        isNetworking(): boolean {
            const hasStatus = Boolean(this.actionStatus);
            const hasNetworkingHost = Boolean(this.networkingData?.hostName);

            return hasStatus && hasNetworkingHost;
        },

        myTitle(): string {
            let returnValue = this.slotData.title;

            if (this.isItemPending && !this.isSessionOver) {
                returnValue = `PENDING - ${returnValue}`;
            } else if (this.isItemPending && this.isSessionOver) {
                returnValue = `Session Is Over - ${returnValue}`;
            }

            return returnValue;
        },

        isItemDeclined(): boolean {
            const id = this.slotData.id || "";
            const rsvpsNo = schedulingStore.itemsWithRsvpNoResponses;

            return Boolean(
                rsvpsNo.includes(id) || "declined" === this.actionStatus
            );
        },

        isItemDeleted(): boolean {
            const id = this.slotData.id || "";
            const deletedItems = appointmentsStore.deletedAppointments;
            return Boolean(deletedItems.includes(id));
        },

        isItemConfirmed(): boolean {
            const id = this.slotData.id || "";
            const rsvps = schedulingStore.itemsWithRsvpYesResponses;

            return Boolean(
                rsvps.includes(id) || "confirmed" === this.actionStatus
            );
        },

        isItemPending(): boolean {
            return Boolean(
                this.isNetworking &&
                    !this.isItemConfirmed &&
                    !this.isItemDeclined
            );
        },

        hasExpired(): boolean {
            return this.isItemPending && this.isSessionOver;
        },

        errorMessage(): string {
            let returnValue = "there was an error saving your rsvp.";

            if (404 === this.errorStatus) {
                returnValue =
                    "that appointment does not exist. It is possible the host has cancelled the meeting.";
            }

            return returnValue;
        },

        sessionDateTime(): Date {
            const iso = this.slotData.startTime || "";
            return new Date(iso);
        },

        startTimeAdjusted(): Date {
            const earlyMinutes = this.sessionEarlyAccessMinutes;
            const startTime = this.sessionDateTime;

            return subMinutes(startTime, +earlyMinutes);
        },

        createActivityIsActive(): boolean {
            const optionChecker = this.$store.getters.isPageOptionActiveInEnv;
            return optionChecker("createActivity", "isActive");
        },

        calendarLinks(): Record<string, any> {
            return calendarStore.calendarLinks;
        },

        //if the sessionData has locationType
        inPersonLocationType(): boolean {
            return Boolean(
                this.slotData.locationType === "In-Person" ? true : false
            );
        },

        singleSession(): SessionDataObject {
            return sessionStore.singleSession;
        },

        isVirtual(): boolean {
            return Boolean(
                this.slotData.locationType === "Virtual" ||
                    this.slotData.locationType === "In-Person and Virtual"
            );
        },

        isInPerson(): boolean {
            return Boolean(
                this.slotData.locationType === "In-Person" ||
                    this.slotData.locationType === "In-Person and Virtual"
            );
        },

        sessionId(): string {
            if (this.slotData.id) {
                return this.slotData.id;
            } else {
                return "";
            }
        }
    },
    /**
     * Lifecycle
     */
    created() {
        this.handleActiveSessionCheck();
        this.scheduleSlotInterval = window.setInterval(() => {
            this.handleActiveSessionCheck();
        }, 1000);

        eventHub.$on("BuefyModalBound", this.handleBuefyModalFixes);
    },

    beforeDestroy() {
        window.clearInterval(this.scheduleSlotInterval);
        eventHub.$off("BuefyModalBound", this.handleBuefyModalFixes);
    },
    methods: {
        /**
         * methods
         */

        checkSpeaker(data: any) {
            const sp = data.filter((item: any) => {
                return item.name != "";
            });

            return sp.length ? true : false;
        },
        getScheduleAttendee(id: string) {
            const result = attendeeScheduleStore.scheduleAttendees.find(
                (item) => id === item.attendeeId
            );
            return result ? result : {};
        },

        addMeToConfirmed() {
            const myName = this.userInfo.name as string;
            const pendingIndex = this.pendingNames.indexOf(myName);

            if (-1 < pendingIndex) {
                this.pendingNames.splice(pendingIndex, 1);
            }

            if (!this.confirmedNames.includes(myName)) {
                this.confirmedNames.push(myName);
            }
        },

        addMeToDeclined() {
            const myName = this.userInfo.name as string;
            const confirmedIndex = this.confirmedNames.indexOf(myName);
            const pendingIndex = this.pendingNames.indexOf(myName);

            if (-1 < pendingIndex) {
                this.pendingNames.splice(pendingIndex, 1);
            }

            if (-1 < confirmedIndex) {
                this.confirmedNames.splice(confirmedIndex, 1);
            }

            if (!this.declinedNames.includes(myName)) {
                this.declinedNames.push(myName);
            }
        },

        resetSaveFlags() {
            this.saveError = false;
            this.errorStatus = null;
        },

        resetRemoveFlags() {
            this.removeError = false;
        },

        handleInviteActionCancel() {
            this.showAcceptModal = false;
        },

        acceptAppointment() {
            this.showAcceptModal = true;
        },

        acceptAppointmentConfirm() {
            const id = this.slotData.id || "";

            this.showAcceptModal = false;

            this.savingRSVP = true;
            schedulingStore
                .acceptAppointment(id)
                .then(() => {
                    this.addMeToConfirmed();
                    schedulingStore.addRsvpItem(id);
                })
                .catch((error) => {
                    const status = error?.response?.status;
                    this.errorStatus = status;
                    this.saveError = true;
                })
                .finally(() => {
                    this.savingRSVP = false;
                    this.isAccepted = true;
                });
        },

        declineAppointmentConfirm() {
            const id = this.slotData.id || "";

            this.savingRSVP = true;
            schedulingStore
                .declineAppointment(id)
                .then(() => {
                    schedulingStore.addRsvpNoItem(id);
                    this.addMeToDeclined();
                })
                .catch((error) => {
                    const status = error?.response?.status;
                    this.errorStatus = status;
                    this.saveError = true;
                })
                .finally(() => {
                    this.savingRSVP = false;
                });
        },
        editAppointment() {
            const appointmentData = JSON.stringify(this.slotData);
            this.$router.push({
                name: "CreateActivity",
                params: {
                    appointment: appointmentData
                }
            });
        },
        deleteAppointment() {
            const id = this.slotData.id || "";

            this.isRemovingItem = true;
            appointmentsStore
                .deleteAppointment(id)
                .catch(() => {
                    this.removeError = true;
                })
                .finally(() => {
                    this.isRemovingItem = false;
                });
        },

        handleActiveSessionCheck() {
            const session = this.slotData;
            this.isSessionActive = sessionHelpSessionIsActive(session);
            this.isSessionOver = sessionHelpSessionIsOver(session);

            // primarily for debugging
            this.myEarlyMins = sessionHelpEarlyAccessMinutes(session);
        },

        goToScheduledItem() {
            const url = this.slotData.url || "";

            // to lower for using in .includes()
            const urlLower = url.toLowerCase();

            if (url) {
                let isUrl = false;
                try {
                    new URL(url);
                    isUrl = true;
                } catch (error) {
                    // no need to log error
                }

                if (isUrl) {
                    window.open(url, undefined, "noopener,noreferrer");
                } else if (urlLower.includes(`sessions/${this.slotData.id}`)) {
                    const timeSinceStart = differenceInSeconds(
                        window.MgServerTime,
                        this.startTimeAdjusted
                    );

                    this.$router.push({
                        name: "Session",
                        params: {
                            id: this.slotData.id || "0",
                            startVideoAt: `${timeSinceStart}`
                        }
                    });
                } else {
                    this.$router.push(url);
                }
            }
        },

        getCalendarLinks() {
            this.showCalendarLinksModal = true;
            this.isLoadingCalendarLinks = true;
            const promises = [];

            calendarStore.resetCalendarLinks();

            for (const value of Object.values(CalendarTypes)) {
                const payload = {
                    type: value,
                    scheduleItemData: this.slotData
                };
                const promise = calendarStore.getAddToCalendarLink(payload);

                promises.push(
                    // .catch so if some calls fail - the others can still be used
                    promise.catch((e) => {
                        e;
                    })
                );
            }

            Promise.all(promises).finally(() => {
                this.isLoadingCalendarLinks = false;
            });
        },

        handleBuefyModalFixes(el: HTMLElement) {
            a11yFixBuefyModalAriaAttrs(el);
        }
    }
});
