



















































































































































































































































































































































































import eventHub from "@/event-hub";

import {
    a11yFixBuefyModalAriaAttrs,
    a11yFixBuefyNotificationAriaAttrs
} from "@/services/a11y";

import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import {
    AttendeeScheduleSlot,
    PendingSessionEvaluation,
    SessionDataArray,
    SessionDataObject,
    SessionSpeakerObject
} from "@/types/interfaces";
import Spinners from "@/components/utilities/Spinners.vue";
import FavoriteButton from "@/components/shared/FavoriteButton.vue";

import sessionVuexModule from "@/store/vuex-modules/getSessionData";
const sessionStore = getModule(sessionVuexModule);

import evalsVuexModule from "@/store/vuex-modules/session-evaluations";
const evalsStore = getModule(evalsVuexModule);

import attendeeScheduleVuexModule from "@/store/vuex-modules/getAttendeeSchedule";
const attendeeScheduleStore = getModule(attendeeScheduleVuexModule);

import gtmVuexModule from "@/store/vuex-modules/gtm";
const gtmStore = getModule(gtmVuexModule);

import store from "@/store"

interface CategoryNameMapping {
    param: string;
    displayName: string;
}

export default Vue.extend({
    // data
    data(){
        return {
            isLoading : true,
            isModalOpen : false,
            activeItemIsSponsor : false,
            sessionFilter : "",
            searchString : "",
            lastTrackedQuery : "",
            lastTrackedFilter : "",
            componentId : Vue.prototype.MgGetUUID(),
            isFetchingModal : false,
            isLoadingFavorites : true,
            modalSession: {} as 
                | Partial<AttendeeScheduleSlot>
                | AttendeeScheduleSlot
                | Partial<SessionDataObject>
                | SessionDataObject,
            filteredResult: [],
            sessionTypeFilter: "" as string,
            sessionCategoryFilter: "" as string,
            tag3SessionFilter: "" as string,
        }
    },
    components: {
        Spinners,
        FavoriteButton
    },
    directives: {
        addbuefymodal: {
            bind: function(el) {
                eventHub.$emit("BuefyModalBound", el);
            }
        },
        addbuefyformfield: {
            bind: function(el) {
                eventHub.$emit("BuefyFormFieldBound", el);
            }
        },
        addbuefynotification: {
            bind: function(el) {
                eventHub.$emit("BuefyNotificationBound", el);
            }
        }
    },
        // getters
    computed: {
        tagsSessionType(): Array<string | undefined> {
            const arr = this.sessionData.flatMap((data: any) => {
                    return data.tags ? data.tags : [];
                })
                .map((item) => {
                    return item?.content;
                });

            return [...new Set(arr)].sort();
        },

        tag3FilterOptions(): Array<string | undefined> {
            const arr = this.sessionData.flatMap((data: any) => {
                return data.tags3 ? data.tags3 : [];
            });
            // const arr = this.filteredSessionsByCategory.flatMap((data: any) => {
            //     return data.tags3 ? data.tags3 : [];
            // });
            

            return [...new Set(arr)].sort();
        },
        routeIsSchedule(): boolean {
            const routeName = this.$route.name;
            return Boolean("Schedule" === routeName);
        },
    
        pageOptions(): Record<string, any> {
            return store.getters.getPageOptions("sessions") || {};
        },
    
        pageOptionsOndemand(): Record<string, any> {
            return store.getters.getPageOptions("onDemand") || {};
        },
    
        evalOptions(): Record<string, any> {
            return store.getters.getPageOptions("sessionEvals") || {};
        },
    
        evalsEnabled(): boolean {
            return this.evalOptions.enabled === true;
        },
    
        speakerFields(): Array<string> {
            return this.pageOptions && this.pageOptions.speakerFields
                ? this.pageOptions.speakerFields
                : ["title", "companyName"];
        },
    
        ondemandDefaultImage(): string {
            let returnValue = "";
            const fallback = this.pageOptionsOndemand.ondemandFallBackImage;
            if (fallback) {
                returnValue = `/fallback-images/${this.pageOptionsOndemand.ondemandFallBackImage}`;
            }
            return returnValue;
        },
    
        modalSessionId(): string | number | undefined {
            return this.modalSession.sessionId || this.modalSession.id;
        },
    
        layoutOptions(): Record<string, string> {
            return this.$store.getters.layoutOptions;
        },
    
        sponsorVideos(): string {
            return this.$store.getters.sponsorVideos;
        },
    
        contentHeading(): string {
            const returnValue = "On-Demand Sessions";
            return returnValue;
        },
    
        sessionData(): Array<SessionDataObject> {
            let returnValue = [],
                data;
    
            if (this.routeIsSchedule) {
                data = attendeeScheduleStore.scheduleDataList;
            } else {
                data = sessionStore.sessionOnDemand;
            }
    
            returnValue = this.filterSessionData(data);
    
            // TS complains about .find not being callable in union type
            // here we restore array methods to the array of data
            return [...JSON.parse(JSON.stringify(returnValue))];
        },
    
        allSessionCategories(): Array<string> {
            const categoryList: Array<string> = [];
    
            this.sessionData.forEach((session: any) => {
                if (Array.isArray(session.categories)) {
                    session.categories.forEach((category: string) => {
                        if (!categoryList.includes(category)) {
                            categoryList.push(category);
                        }
                    });
                }
            });
    
            return categoryList.sort((a, b) => {
                return a.localeCompare(b);
            });
        },
    
        filteredSessionsByCategory(): Array<AttendeeScheduleSlot | SessionDataArray | SessionDataObject> {
            const filteredData = this.sessionData
                .filter(
                    (session: any) =>
                        session.categories &&
                        session.categories.includes(this.sessionFilter)
                );

            return this.filteredSession(filteredData)
           
        },
    
        sessionsToUse(): Array<AttendeeScheduleSlot | SessionDataArray | SessionDataObject> {
            return this.useSessionFilter
                ? this.filteredSessionsByCategory
                : this.sessionData;
        },
    
        useSessionFilter(): boolean {
            return this.pageOptions.onDemandCategoryFilter;
        },
    
        categoryNameMappings(): Array<string | undefined> {
            return this.pageOptions.onDemandCategoryMappings
                ? this.pageOptions.onDemandCategoryMappings
                : [];
        },
    
        myEvaluations(): Array<PendingSessionEvaluation> {
            return evalsStore.myPendingEvals;
        },
    
        evalForModalSession(): any {
            if (!this.isModalOpen) {
                return undefined;
            } else {
                return this.myEvaluations.find(
                    (evaluation) => evaluation.sessionId === this.modalSessionId
                );
            }
        }
    },

    /**
     * Watchers
     */
    watch: {
        // @Watch("sessionFilter")
        searchString: function() {
            // this.sessionFilter = this.searchString;
            
            this.updateSessionFilter()
        },

    },

    // lifecycle
    created() {
        eventHub.$on("BuefyModalBound", this.handleBuefyModalFixes);
        eventHub.$on(
            "BuefyNotificationBound",
            this.handleBuefyNotificationFixes
        );

        this.tag3SessionFilter = (this.$route.query.track ? this.$route.query.track : "") as string;
        this.sessionTypeFilter = (this.$route.query.topic ? this.$route.query.topic : "") as string;
        this.searchString = (this.$route.query.onDemandFilter ? this.$route.query.onDemandFilter : "") as string;
        
        

        const promises = [attendeeScheduleStore.getFavoriteSessions()];

        if (this.evalsEnabled) {
            promises.push(evalsStore.getMyPendingEvals());
        }

        if (this.routeIsSchedule) {
            const query = attendeeScheduleStore.getAttendeeSchedule({
                only: "past",
                flatList: true
            });
            promises.push(query);
        } else {
            const query = sessionStore.getSessionData({
                isDemoData: false
            });
            promises.push(query);
        }

        Promise.allSettled(promises).then(() => {
            this.isLoading = false;
            this.isLoadingFavorites = false;

            this.openModalFromParam();
            this.sessionFilterFromUrl();
        });

    },

    beforeDestroy() {
        eventHub.$off("BuefyModalBound", this.handleBuefyModalFixes);
        eventHub.$off(
            "BuefyNotificationBound",
            this.handleBuefyNotificationFixes
        );
    },
    methods: {
        /**
         * methods
         */
        resetSearch() {
            this.searchString = "";
            this.sessionFilter = "";
            this.sessionTypeFilter = "";
            this.sessionCategoryFilter= "";
            this.tag3SessionFilter= "";
            this.$router.push("/sessions");
        },
        sessionFilterFromUrl() {
            const onDemandFilter = this.$route.query.onDemandFilter;
            if (onDemandFilter && "string" === typeof onDemandFilter) {
                return (this.sessionFilter = onDemandFilter);
            } else {
                this.updateSessionFilter();
            }
        },
        async showModal(
            session: AttendeeScheduleSlot | SessionDataObject | SessionDataArray,
            isSponsor: boolean
        ) {
            this.isFetchingModal = true;
            this.activeItemIsSponsor = isSponsor;
            this.modalSession = session as any;
    
            this.isModalOpen = true;
            this.isFetchingModal = false;
        },
    
        openModalFromParam() {
            const sessionId =
                this.$route.query && this.$route.query.onDemandOpen
                    ? this.$route.query.onDemandOpen.toString()
                    : "";
    
            if (sessionId) {
                const session = this.sessionData.find((session: any) => {
                    const id = session.sessionId || session.id;
                    return Boolean(id === sessionId);
                });
    
                if (session) {
                    this.showModal(session, false);
                }
            }
        },
    
        searchOnDemand(cb: any) {
            if (this.searchString && this.searchString != this.lastTrackedQuery) {
                gtmStore.gtmTrack({
                    category: "On Demand",
                    action: "Search",
                    label: this.searchString
                });
    
                this.lastTrackedQuery = this.searchString;
            }
    
            return cb.filter(
                (session: any) =>
                    session.title
                        .toLowerCase()
                        .includes(this.searchString.toLowerCase()) ||
                    (session.description &&
                        session.description
                            .toLowerCase()
                            .includes(this.searchString.toLowerCase()))
            );
        },
    
        mappedName(paramName: string) {
            if (!this.categoryNameMappings.length) {
                return paramName;
            }
    
            const thisItem = this.categoryNameMappings.find(
                (mapping: any) => mapping.param === paramName
            )as any;
    
            return thisItem ? thisItem.displayName : paramName;
        },
    
        openEvaluation() {
            if (this.evalForModalSession) {
                evalsStore.activateSessionEval(this.evalForModalSession);
            }
        },
    
        filterSessionData(data: Array<AttendeeScheduleSlot | SessionDataObject>) {
            return data.filter((item) => {
                const isTrue = (currentValue: boolean) => currentValue;
                const hasOndemandVideo = Boolean(item.videoUrlOnDemand);
                const conditions = [hasOndemandVideo];
                return conditions.every(isTrue);
            });
        },
    
        handleBuefyModalFixes(el: HTMLElement) {
            a11yFixBuefyModalAriaAttrs(el);
        },
    
        handleBuefyNotificationFixes(el: HTMLElement) {
            a11yFixBuefyNotificationAriaAttrs(el);
        },
    
        formattedSpeakerText(speaker: SessionSpeakerObject) {
            const fields = this.speakerFields;
    
            let data = "";
    
            const commaFields = [] as Array<string>;
    
            fields.forEach((field: string) => {
                const key = field as keyof SessionSpeakerObject;
    
                if (speaker[key]) {
                    commaFields.push(`${speaker[key]}`);
                }
            });
    
            if (commaFields.length) {
                data += ` &ndash; ${commaFields.join(", ")}`;
            }
    
            return data;
        },

        filteredSession(data: Array<SessionDataObject>){
            const isSessionFilter = this.sessionFilter
                ? data
                : this.sessionData.sort((a: any, b: any) =>
                    a.title.trim().localeCompare(b.title.trim())
                ); 

            const fetchedData = isSessionFilter.filter((item) => {
                if (!this.sessionTypeFilter) {
                        return item;
                    } else if (
                        this.sessionTypeFilter &&
                        Array.isArray(item.tags)
                    ) {
                        return item.tags.some((tag) => {
                            return tag.content.includes(this.sessionTypeFilter);
                        });
                    }
            }).filter((tag3item) => {
                if((this.tag3SessionFilter && Array.isArray(tag3item.tags3))){
                    return tag3item.tags3.includes(this.tag3SessionFilter);
                }else if(!this.tag3SessionFilter) {
                    return tag3item;
                }
            })

            if (this.searchString) {
                return this.searchOnDemand(fetchedData);
            } else {
                return fetchedData;
            }
        },

        updateSessionFilter() {
            const query = this.$route.query ? { ...this.$route.query } : {};
            query.onDemandFilter = this.sessionFilter;
    
            if (
                this.sessionFilter &&
                this.sessionFilter != this.lastTrackedFilter
            ) {
                gtmStore.gtmTrack({
                    category: "On Demand",
                    action: "Filter",
                    label: this.sessionFilter
                });
                this.lastTrackedFilter = this.sessionFilter;
            }
    
            this.$router.push({
                query: query
            });
        },

        updateTrackParams(value: string) {
            this.updateFilterParam(value, "track");
        },
        updateTopicParams(value: string) {
            this.updateFilterParam(value, "topic")
        },
        updateFilterParam(term: string, params: string) {
            const query = this.$route.query ? { ...this.$route.query } : {};
            query[params] = term;

            this.$router.push({
                query: query
            });
        },
    }

})
