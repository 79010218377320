import { Module, VuexModule, Action } from "vuex-module-decorators";
import Store from "../index";
import { GtmTrackData } from "@/types/interfaces";

interface GtmTrackOutput {
    event: string;
    vmpCategory: string;
    vmpAction: string;
    vmpLabel?: string;
}

/**
 * Module for tracking information to Google Tag Manager.
 *
 * IMPORTANT: Make sure we're not tracking any personal information like names or emails here!
 */

@Module({
    dynamic: true,
    store: Store,
    name: "GtmModule",
    namespaced: true
})
export default class GtmModule extends VuexModule {
    @Action({ rawError: true })
    gtmTrack(data: GtmTrackData) {
        window.dataLayer = window.dataLayer || [];

        const transformedData = {
            event: "vmpCustomEvent",
            vmpCategory: data.category,
            vmpAction: data.action
        } as GtmTrackOutput;

        if (data.label) {
            transformedData.vmpLabel = data.label;
        }

        window.dataLayer.push(transformedData);
    }
}
