















































































import Vue from "vue";
// import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";

// Vuex Modules
import sessionEvalVuexModule from "@/store/vuex-modules/session-evaluations";
const evalStore = getModule(sessionEvalVuexModule);

// Types
import { PendingSessionEvaluation } from "@/types/interfaces";

// Components
import Spinners from "@/components/utilities/Spinners.vue";

export default Vue.extend({
    components: {
        Spinners
    },
    data() {
        return {
            loading: false,
            showInvalidModal: false
        };
    },
    computed: {
        myPendingEvals(): Array<PendingSessionEvaluation> {
            return evalStore.myPendingEvals;
        },
        openIdParam(): string {
            return this.$route.query.evaluationId
                ? `${this.$route.query.evaluationId}`
                : "";
        },
        evalFromParam(): PendingSessionEvaluation | undefined {
            return this.myPendingEvals.find(
                (evaluation) => evaluation.sessionId === this.openIdParam
            );
        },
        invalidIdMessage(): string {
            let message = "";

            if (!this.evalFromParam) {
                message =
                    "The requested session evaluation could not be found.";
            }

            if (this.evalFromParam && this.evalFromParam.hasCompleted) {
                const nameForMessage = this.evalFromParam.title
                    ? this.evalFromParam.title
                    : "this session";
                message = `You've already completed a session evaluation for ${nameForMessage}.`;
            }

            return message;
        }
    },
    mounted() {
        this.initialSetup();
    },
    methods: {
        async initialSetup() {
            this.loading = true;
            await evalStore.getMyPendingEvals();
            this.loading = false;

            if (this.evalFromParam && !this.evalFromParam.hasCompleted) {
                this.activateEval(this.evalFromParam);
            } else if (this.openIdParam) {
                this.showInvalidModal = true;
            }
        },
        async activateEval(sessionEval: PendingSessionEvaluation) {
            this.loading = true;
            await evalStore.activateSessionEval(sessionEval);
            this.loading = false;
        }
    }
});
