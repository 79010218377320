import {
    config,
    Module,
    VuexModule,
    Mutation,
    Action
} from "vuex-module-decorators";
import { AttendeeScheduleSlot } from "@/types/interfaces";
import { getApiClient } from "@/services/api";
import { CalendarTypes } from "@/types/enums";
import Store from "../index";

const endpoint = `calendar`;

// Set rawError to true by default on all @Action decorators
config.rawError = true;

interface CalendarLinkOptions {
    type: CalendarTypes;
    scheduleItemData: AttendeeScheduleSlot;
}

type CalendarLinksType = { [key in CalendarTypes]?: string };

@Module({
    dynamic: true,
    store: Store,
    name: "CalendarModule",
    namespaced: true
})
export default class CalendarModule extends VuexModule {
    calendarLinks: CalendarLinksType = {};

    @Mutation
    resetCalendarLinks() {
        this.calendarLinks = {};
    }

    @Mutation
    setCalendarLink(
        payload = {
            type: "",
            value: ""
        }
    ) {
        // TODO:
        // payload param above should have `type: CalendarTypes`

        // Not proud of this code but it works.
        // There is likely a better way.
        for (const aCalType of Object.values(CalendarTypes)) {
            if (payload.type === aCalType) {
                let link = payload.value;

                if (["ics"].includes(aCalType)) {
                    link = `data:text/calendar,${encodeURIComponent(link)}`;
                }

                this.calendarLinks[aCalType] = link;
                break;
            }
        }
    }

    @Action({})
    async getAddToCalendarLink(payload: CalendarLinkOptions) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .post(`${endpoint}`, payload, {
                    headers: { Authorization: `bearer ${token}` }
                })
                .then((response) => {
                    const thetype = payload.type;
                    const updatePayload = {
                        type: thetype,
                        value: response.data
                    };

                    this.context.commit("setCalendarLink", updatePayload);

                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }
}
