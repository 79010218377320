<template>
    <div>
        <PageTitleHeader
            :pageAccessor="pageHeaderImageAccessor"
            class="h-32 md:h-64"
        >
            <h1
                class="title mg-title-header text-center mg-font-bold pb-10 text-lg md:text-4xl xl:text-5xl"
            >
                <span v-html="textHeader"></span>
            </h1>
        </PageTitleHeader>
        <div class="session-container">
            <section
                class="carousel-container"
                :class="
                    cssVariables['--session-carousel-bg-color']
                        ? 'bg-session-carousel'
                        : 'text-primary'
                "
            ></section>

            <section class="table-schedule-container">
                <div
                    class="table-schedule-body"
                    :class="{
                        'bg-transparent': Boolean(imagePageHeader)
                    }"
                >
                    <!-- 
                        `destroy-on-hide` - Keep this in place.
                        This is for improved performance. 
                        Each schedule item runs setinterval code, 
                        with `destroy-on-hide` we only run nessecary interval code / freeing up JS thread.
                    -->
                    <b-tabs
                        class="mg-directory-tabs mg-directory-tabs--live-tab-1 mg-directory-tabs--transparent-tab-2 mg-tabs-no-margin"
                        ref="Tabs"
                        v-model="activeTab"
                        position="is-centered"
                        type="is-boxed"
                        :animated="animated"
                        v-addbuefytabs
                        destroy-on-hide
                    >
                        <template>
                            <b-tab-item
                                :label="checkSessionLabel"
                                icon="calendar-alt"
                                class="bg-tab-container"
                            >
                                <component :is="liveComponentToUse" />
                            </b-tab-item>
                            <b-tab-item
                                v-if="!pageOptions.hideOnDemandTab"
                                :label="
                                    getConfigurableLabel.recordedLabel
                                        ? getConfigurableLabel.recordedLabel
                                        : 'On Demand'
                                "
                                icon="file-video"
                                class="bg-tab-container"
                            >
                                <prerecorded-listing v-if="1 === activeTab" />
                            </b-tab-item>
                            <b-tab-item
                                v-if="!pageOptions.disableSpeakerTab"
                                label="Speakers"
                                class="bg-tab-container"
                                icon="user-alt"
                            >
                                <SpeakersList />
                            </b-tab-item>
                            <b-tab-item
                                v-if="shouldShowEvalTab"
                                label="Evaluations"
                                class="bg-tab-container"
                                icon="hand-point-up"
                            >
                                <EvalsList />
                            </b-tab-item>
                        </template>
                    </b-tabs>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import {
    initDomObserverForA11yAttrFixes,
    a11yFixBuefyTabsAriaAttrs,
    a11yFixBuefyTabHrefs
} from "@/services/a11y";

import { getModule } from "vuex-module-decorators";
import svgCalendar from "@/components/svg/svg-calendar.vue";
import Schedule from "@/components/schedule/Schedule.vue";
import SessionSchedule from "@/components/schedule/SessionSchedule.vue";
import UpcomingSessionsListing from "@/components/sessions/UpcomingSessionsListing";
import PrerecordedListing from "@/components/sessions/PrerecordedListing.vue";
import SpeakersList from "@/components/sessions/SpeakersList.vue";
import EvalsList from "@/components/sessions/EvalsList.vue";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import sessionVuexModule from "@/store/vuex-modules/getSessionData";
import layoutImagesVuexModule from "@/store/vuex-modules/layoutImages";
import ScheduleView from "./ScheduleView.vue";

const layoutImagesStore = getModule(layoutImagesVuexModule);
const sessionStore = getModule(sessionVuexModule);

export default {
    name: "SessionsView",
    components: {
        UpcomingSessionsListing,
        Schedule,
        SessionSchedule,
        PrerecordedListing,
        PageTitleHeader,
        SpeakersList,
        ScheduleView,
        EvalsList
    },

    directives: {
        addbuefytabs: {
            bind: function(el, binding, vnode) {
                vnode.context.handleBuefyTabsObserver(el);
            }
        }
    },

    data() {
        return {
            buefyTabsObserver: null,
            defaultSessionHeader: "CURRENT LIVE STREAMS",
            activeCategory: "ALL",
            SessionInfo: null,
            slides: 7,
            value: 0,
            activeTab: 0,
            animated: false,
            Repeat: false,
            Autoplay: false,
            recordedTrackInfo: [],
            words: [
                "J9e7Z_nmark",
                "jQJbteB5Vr0",
                "fatUQpJR3UA",
                "LJVBPkRsuNQ",
                "2xpJaxNPs0Q",
                "T4iC5LaYWeY",
                "rYHDXEmzCaA"
            ],
            liveData: [],
            striped: true,
            liveColumns: [
                { field: "Name", label: "Track", sticky: true },
                { field: "First", label: "8:30am", width: 300 },
                { field: "Second", label: "9:30am", width: 150 },
                { field: "Third", label: "10:30am" },
                { field: "Fourth", label: "11:30am" },
                { field: "Fifth", label: "12:30pm" },
                { field: "Sixth", label: "1:30pm" },
                { field: "Seventh", label: "2:30pm" }
            ]
        };
    },

    computed: {
        imagePageHeader() {
            if (layoutImagesStore.getLayoutImage("sessionHeader")) {
                return layoutImagesStore.getLayoutImage("sessionHeader");
            }
            return layoutImagesStore.getLayoutImage("pagePrimary");
        },

        showActiveCategory() {
            if (this.activeCategory === "ALL") {
                return this.activeCategory;
            }
            const session = this.recordedTrackInfo.find(
                (category) => category.name === this.activeCategory
            );
            return Object.values(session.sessions);
        },

        cssVariables() {
            return this.$store.getters.cssVariables;
        },

        liveContentBgColor() {
            return this.cssVariables["--session-live-content-bg-color"];
        },

        getConfigurableLabel() {
            return this.$store.getters.sessionLabel;
        },

        getLayoutOptions() {
            return this.$store.getters.layoutOptions;
        },

        pageOptions() {
            return this.$store.getters.getPageOptions("sessions") || {};
        },

        scheduleOptions() {
            return this.$store.getters.getPageOptions("schedule") || {};
        },

        evalOptions() {
            return this.$store.getters.getPageOptions("sessionEvals") || {};
        },

        shouldShowEvalTab() {
            return this.evalOptions.enabled === true;
        },

        liveComponentToUse() {
            let returnValue = "ScheduleView";

            if (this.routeIsSchedule) {
                return (returnValue = "Schedule");
            } else {
                return returnValue;
            }
        },

        routeIsSchedule() {
            const routeName = this.$route.name;
            return Boolean("Schedule" === routeName);
        },

        textHeader() {
            const scheduleHeading = this.scheduleOptions.textHeader
                ? this.scheduleOptions.textHeader
                : "SCHEDULE";

            const sessionsHeading = this.pageOptions.textHeader
                ? this.pageOptions.textHeader
                : "SCHEDULE";

            if (this.routeIsSchedule) {
                return scheduleHeading;
            } else {
                return sessionsHeading;
            }
        },

        checkSessionLabel() {
            const upcomingSessions = sessionStore.sessionData.upcomingSessions;
            return upcomingSessions.length ? "UPCOMING" : "PROGRAM";
        },

        pageHeaderImageAccessor() {
            return this.routeIsSchedule ? "scheduleHeader" : "sessionHeader";
        }
    },

    watch: {
        activeTab() {
            if (0 === this.activeTab && location.search) {
                return this.$router.push({
                    name: this.$route.name
                });
            }

            if (2 === this.activeTab) {
                const router = this.$router.currentRoute.name;
                return this.$router.push({
                    path: router,
                    query: { speakers: "" }
                });
            }

            if (this.getLayoutOptions.usesAlternateSessionRecordedLayout) {
                return "";
            }

            this.$nextTick(() => {
                if (1 === this.activeTab) {
                    // this.$router.push("?onDemandOpen");
                    for (let i = 0; i < this.recordedTrackInfo.length; i++) {
                        const which = this.recordedTrackInfo[i].name;
                        this.$refs[which][0].update();
                    }
                } else {
                    this.$router.push("");
                }
            });
        }
    },

    created() {
        if (
            this.$route.query &&
            ("onDemandOpen" in this.$route.query ||
                "onDemandFilter" in this.$route.query)
        ) {
            this.activeTab = 1;
        }

        if (
            this.shouldShowEvalTab &&
            this.$route.query &&
            "evaluationId" in this.$route.query
        ) {
            this.activeTab = 3;
        }
    },

    beforeDestroy() {
        if (this.buefyTabsObserver instanceof MutationObserver) {
            this.buefyTabsObserver.disconnect();
        }
    },
    methods: {
        handleBuefyTabsObserver(el) {
            if (this.buefyTabsObserver instanceof MutationObserver) {
                return;
            }
            this.buefyTabsObserver = initDomObserverForA11yAttrFixes(
                el,
                function() {
                    a11yFixBuefyTabsAriaAttrs(el);
                    a11yFixBuefyTabHrefs(el);
                }
            );
        }
    }
};
</script>
<style lang="scss" scoped>
@import "../styles/views/session";
</style>
