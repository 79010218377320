














































import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import { SessionDataArray } from "@/types/interfaces";
import sessionVuexModule from "@/store/vuex-modules/getSessionData";
import Spinners from "@/components/utilities/Spinners.vue";
import UpcomingSessionItem from "@/components/sessions/UpcomingSessionItem.vue";

const sessionStore = getModule(sessionVuexModule);

export default Vue.extend({
    data() {
        return {
            isLoading: true
        };
    },
    components: {
        Spinners,
        UpcomingSessionItem
    },

    created() {
        sessionStore
            .getSessionData({
                isDemoData: false
            })
            .finally(() => {
                this.isLoading = false;
            });
    },
    computed: {
        sessionData(): SessionDataArray {
            if (sessionStore.sessionData) {
                return sessionStore.sessionData.upcomingSessions.map(
                    (session) => {
                        return { ...session, isExpanded: false };
                    }
                );
            } else {
                return [];
            }
        }
    }
});
